<template>
  <p
    v-show="showCountDown"
    class="c-count-down-time cart__down-time not-fsp-element"
    :class="[showType == 'inline-promotion' ? 'new-down-time' : 'count-down-time', {active: diffDays == 0}]"
  >
    <span
      v-if="!hideTip"
      :class="{'activity-tips': showEndTips}"
    >
      {{ showEndTips ? language.SHEIN_KEY_PC_24840 : (showType == 'inline-promotion' ? '' : template('', language.SHEIN_KEY_PC_17111)) }}
    </span>
    <span class="time-content">
      <span
        v-show="diffDays"
        class="count-number count-down-days"
      >{{ daysComputed }}&nbsp;</span>
      <span
        class="count-number count-down-hours"
        :style="{'fontSize': fontSize}"
      >{{ twoNums(diffHours) }}</span>:
      <span
        class="count-number count-down-mins"
        :style="{'fontSize': fontSize}"
      >{{ twoNums(diffMinutes) }}</span>:
      <span
        class="count-number count-down-secs"
        :style="{'fontSize': fontSize}"
      >{{ twoNums(diffSeconds) }}</span>
    </span>
  </p>
</template>
<script>
import i18n from 'public/src/pages/common/biz_helper/i18n'
import { template } from '@shein/common-function'

export default {
  props: {
    countDown: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    timeStamp: {
      type: Number,
      required: false,
      default: 0
    },
    isLeftTime: {
      type: Boolean,
      required: false,
      default: false
    },
    showType: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: '12px'
    },
    showEndTips: {
      type: Boolean,
      default: false
    },
    // 倒计时归零时，不隐藏倒计时
    notHide: {
      type: Boolean,
      default: false
    },
    // 倒计时归零时，不刷新页面
    notReload: {
      type: Boolean,
      default: false
    },
    hideTip: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      language: {},
      diffDays: '',
      diffHours: '',
      diffMinutes: '',
      diffSeconds: '',
      timer: null,
      showCountDown: true
    }
  },
  computed: {
    daysComputed () {
      const { diffDays } = this
      if (diffDays > 1) {
        return `${diffDays} ${this.language.SHEIN_KEY_PC_17212 || 'Days'}`
      } else if (diffDays == 1) {
        return `${diffDays} ${this.language.SHEIN_KEY_PC_15173 || 'Day'}`
      } else {
        return ''
      }
    }
  },
  created () {
    this.init() 
  },
  beforeUnmount () {
    if (this.timer) clearInterval(this.timer)
  },
  methods: {
    template: template,
    async init () {
      const countDownData = this.timeStamp ? countDownTime({ endTimestamp: this.isLeftTime ? (Date.now() + (this.timeStamp * 1000)) : this.timeStamp }) : typeof this.countDown == 'object' ? this.countDown : countDownTime({ endTimestamp: this.countDown })
      this.diffDays = ['inline-promotion', 'promotion-tab'].includes(this.showType) ? 0 : +countDownData.diffDays
      this.diffHours = ['inline-promotion', 'promotion-tab'].includes(this.showType) ? +countDownData.diffHours + (+countDownData.diffDays * 24 ) : +countDownData.diffHours
      this.diffMinutes = +countDownData.diffMinutes
      this.diffSeconds = +countDownData.diffSeconds
      this.setTimer()
      if(countDownData.diffTime <= 0) { // init found count down is <= 0, do not render countdown
        this.showCountDown = false
      }
      this.language = await i18n.loadByQueue('promotion')
    },
    setTimer () {
      if (this.timer) {
        // eslint-disable-next-line no-console
        console.log('')
      } else {
        this.timer = setInterval(() => {
          if (this.diffSeconds - 1 >= 0) {
            this.diffSeconds = this.diffSeconds - 1
          } else {
            if (this.diffMinutes - 1 >= 0) {
              this.diffMinutes = this.diffMinutes - 1
              this.diffSeconds = 59
            } else {
              if (this.diffHours - 1 >= 0) {
                this.diffHours = this.diffHours - 1
                this.diffMinutes = 59
                this.diffSeconds = 59
              } else {
                if (this.diffDays - 1 >= 0) {
                  this.diffDays = this.diffDays - 1
                  this.diffHours = 24
                  this.diffMinutes = 59
                  this.diffSeconds = 59
                } else {
                  clearInterval(this.timer)
                  if (!this.notHide) this.showCountDown = false
                  if (!this.notReload) window.location.reload(true)
                }
              }
            }
          }
        }, 1000)
      }
    },
    twoNums (val) {
      if (typeof val === 'number' && !isNaN(val)) {
        return val < 10 ? `0${val}` : `${val}`
      } else {
        return ''
      }
    }
  }
}

/**
 * 计算倒计时
 */
function countDownTime({ endTimestamp = 0 }) {
  let end = endTimestamp || 0
  if(end){
    end = (end + '').length >= 13 ? end : end * 1000
  }
  const now = (new Date()).getTime()
  const diffTime = end - now
  const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24) // 相差天数
  const diffHours = Math.floor(diffTime / 1000 / 60 / 60 - diffDays * 24) // 相差小时
  const diffMinutes = Math.floor(diffTime / 1000 / 60 - diffDays * 24 * 60 - diffHours * 60) // 相差分钟
  const diffSeconds = Math.floor(diffTime / 1000 - diffDays * 24 * 60 * 60 - diffHours * 60 * 60 - diffMinutes * 60) // 相差秒数

  return {
    diffTime,
    diffDays,
    diffHours,
    diffMinutes,
    diffSeconds
  }
}
</script>
<style lang="less">
.cart__down-time{
  margin-top: 6px;
  font-weight: normal;
  color: @sui_color_highlight;
  font-size: 12px;
  .time-content{
    display: inline-block;
    font-weight: normal;
    color: @sui_color_highlight;
    /* rtl:begin:ignore */
    direction: ltr;
    span{
      display: inline;
      font-weight: normal;
      color: @sui_color_highlight;
    }
  }
}
</style>

<style lang="less" scoped>
.new-down-time{
  .count-number{
    background: #fff;
    padding: 1px 2px;
  }
  .time-content{
    color: @sui_color_main;
    span {
      color: @sui_color_main;
    }
  }
}
.activity-tips {
  color: @sui_color_main;
}
</style>
